import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.regexp.to-string";
import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";

function _toConsumableArray(arr) {
  return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _nonIterableSpread();
}

function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance");
}

function _iterableToArray(iter) {
  if (Symbol.iterator in Object(iter) || Object.prototype.toString.call(iter) === "[object Arguments]") return Array.from(iter);
}

function _arrayWithoutHoles(arr) {
  if (Array.isArray(arr)) {
    for (var i = 0, arr2 = new Array(arr.length); i < arr.length; i++) arr2[i] = arr[i];

    return arr2;
  }
}

function _objectWithoutProperties(source, excluded) {
  if (source == null) return {};

  var target = _objectWithoutPropertiesLoose(source, excluded);

  var key, i;

  if (Object.getOwnPropertySymbols) {
    var sourceSymbolKeys = Object.getOwnPropertySymbols(source);

    for (i = 0; i < sourceSymbolKeys.length; i++) {
      key = sourceSymbolKeys[i];
      if (excluded.indexOf(key) >= 0) continue;
      if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue;
      target[key] = source[key];
    }
  }

  return target;
}

function _objectWithoutPropertiesLoose(source, excluded) {
  if (source == null) return {};
  var target = {};
  var sourceKeys = Object.keys(source);
  var key, i;

  for (i = 0; i < sourceKeys.length; i++) {
    key = sourceKeys[i];
    if (excluded.indexOf(key) >= 0) continue;
    target[key] = source[key];
  }

  return target;
}

import React, { useState, useEffect } from 'react';
import { navigate } from 'gatsby';
import queryString from 'query-string';
import { getSearchQuery } from 'helpers';
import { PageTemplate } from '../templates/page';
export const SearchPage = _ref => {
  let {
    data,
    location
  } = _ref,
      props = _objectWithoutProperties(_ref, ["data", "location"]);

  const [query, setQuery] = useState(getSearchQuery('query', location));
  useEffect(() => {
    setQuery(getSearchQuery('query', location));
  }, [location]);

  const onSubmit = ({
    query: newQuery
  }) => {
    navigate("/search/" + (newQuery ? "?" + queryString.stringify({
      query: newQuery
    }) : ''));
  };

  const slicesMiddleware = (list = []) => {
    return [{
      __typename: 'PageBodyHeader',
      id: 'header'
    }].concat(_toConsumableArray(list), [{
      __typename: 'PageBodySearchBar',
      id: 'search-bar',
      initialQuery: query,
      onSubmit
    }, {
      __typename: 'PageBodySearchResults',
      id: 'search-results',
      query
    }, {
      __typename: 'PageBodyFooter',
      id: 'footer'
    }]);
  };

  return React.createElement(PageTemplate, Object.assign({
    data: data,
    location: location,
    slicesMiddleware: slicesMiddleware
  }, props));
};
export default SearchPage;
export const query = "1604691817";